<template>
  <!-- eslint-disable vue/no-deprecated-slot-attribute -->
  <div>
    <amplify-auth-container>
      <amplify-authenticator username-alias="email">
        <amplify-sign-in
          username-alias="email"
          header-text="JH Metaphysics"
          slot="sign-in"
        >
          <span slot="header-subtitle">
            If you are logging in for the first time, please click 'Reset
            password' to verify your email and set a password.</span
          >
          <!-- a method to hide sign ups -->
          <div slot="secondary-footer-content"></div>
        </amplify-sign-in>
        <amplify-forgot-password slot="forgot-password" username-alias="email">
        </amplify-forgot-password>
        <div v-if="authState === 'signedin' && user">
          <!-- The rest of your app code for authenticated users -->
          <div id="nav">
            <TopNav
              :email="this.user.signInUserSession.idToken.payload.email"
            />
            <nav class="nav nav-pills nav-justified mt-3 mx-2">
              <!--
              <a class="nav-link active" aria-current="page" href="#">Active</a>
              <a class="nav-link" href="#">Much longer nav link</a>
              <a class="nav-link" href="#">Link</a>
              <a class="nav-link disabled">Disabled</a> -->
              <router-link to='/' active-class="active" class="nav-link"><i class="fas fa-users"></i> View Customers</router-link>
              <router-link to='/adhoc' active-class="active" class="nav-link"><i class="fas fa-plus"></i> Create Bazi Chart</router-link>
              <router-link to='/multichart' active-class="active" class="nav-link"><i class="fas fa-columns"></i> View Multi-Chart <span class="badge bg-danger" v-if="workbenchCount>0">{{workbenchCount}}</span></router-link>
              <!-- <router-link to='/qimen/hourly' active-class="active" class="nav-link"><i class="far fa-clock"></i> Qimen Hourly</router-link> -->
              <router-link to='/newuser' active-class="active" class="nav-link"><i class="fas fa-user-plus"></i> New Portal User</router-link>
              <router-link to='/eventregistration' active-class="active" class="nav-link"><i class="fas fa-user-graduate"></i> Event Registration</router-link>
              <router-link to='/calendarregistration' active-class="active" class="nav-link"><i class="fas fa-calendar"></i> E-Calendar Buyers</router-link>
              <router-link to='/qimenturnaround' active-class="active" class="nav-link"><i class="fas fa-chess"></i> 奇门个人运程报告</router-link>
            </nav>
          </div>
          <router-view />
          <footer class="footer mt-auto py-3 bg-light">
            <div class="container">
              <div class="row text-center" style="font-size: 0.8rem">
                <small class="text-muted"
                  >Copyright © 2021-2022 JH Metaphysics Consultancy Group. All
                  rights reserved.</small
                >
              </div>
            </div>
          </footer>
        </div>
      </amplify-authenticator>
    </amplify-auth-container>
  </div>
</template>

<script>
// @ is an alias to /src
import TopNav from "@/components/TopNav.vue";

//import Amplify, { Auth, API } from "aws-amplify";
//import Amplify, { Auth } from "aws-amplify";
import Amplify, { Auth } from "aws-amplify";
import { onAuthUIStateChange } from "@aws-amplify/ui-components";

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: "ap-southeast-1",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.VUE_APP_USER_POOL,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.VUE_APP_USER_POOL_WEBCLIENT,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,
  },
  API: {
    endpoints: [
      {
        name: "bazi",
        endpoint: process.env.VUE_APP_BAZI_API,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
          };
        },
      },
      {
        name: "admin",
        endpoint: process.env.VUE_APP_ADMIN_API,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
          };
        },
      },
      {
        name: "calendar",
        endpoint: process.env.VUE_APP_CALENDAR_API,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
          };
        },
      },
      {
        name: "qimenturnaround",
        endpoint: process.env.VUE_APP_QIMENTURNAROUND_API,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
});

export default {
  name: "App",
  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
      if (authData.signInUserSession.accessToken.payload["cognito:groups"]) {
        if (
          !authData.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes("Admins")
        ) {
          Auth.signOut();
          alert("No access to admin system");
        }
      } else {
        Auth.signOut();
        alert("No access to admin system");
      }
      this.$store.commit(
        "updateEmail",
        authData ? authData.attributes.email : null
      );
    });
  },
  data() {
    return {
      user: undefined,
      authState: undefined,
      unsubscribeAuth: undefined,
    };
  },
  components: {
    TopNav,
  },
  beforeUnmount() {
    this.unsubscribeAuth();
  },
  computed: {
    workbenchCount() {
      return this.$store.state.workbench.length
    }
  },
};
</script>

<style>
html,
body {
  overflow-x: hidden; /* Prevent scroll on narrow devices */
}

body {
  padding-top: 11vh;
  background-color: #fff;
}

.yellow-line {
  background-color: #f5df4d;
  width: 150px;
  height: 2px;
}
.grey-line {
  background-color: #75787b;
  height: 0.5px;
}
.subheader {
  color: #75787b;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
