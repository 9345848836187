import { createStore } from 'vuex'
import * as _ from 'lodash'

export default createStore({
  state: {
    userEmail: null,
    workbench: []
  },
  mutations: {
    updateEmail (state, email) {
      state.userEmail = email
    },
    addToWorkbench (state, person) {
      state.workbench.push(person)
    },
    deleteFromWorkbench (state, person) {
      _.remove(state.workbench, person)
    },
    clearWorkbench (state)  {
      state.workbench = []
    }
  },
  actions: {
  },
  modules: {
  }
})
