<template>
  <div class="container">
    <div
      class="d-flex align-items-center mb-5"
      v-if="status == 'generating'"
      :class="{ 'mt-3': !showButtons }"
    >
      <div class="spinner-border text-danger" role="status"></div>
      <div class="ms-3">
        <strong>Generating chart...</strong>
      </div>
    </div>
    <div
      class="d-flex align-items-center mb-5"
      v-if="status == 'done' && showButtons"
    >      
      <button
            type="button"
            class="btn btn-warning mb-2"
            @click="printChart"
            v-bind:disabled="saveChartInProgress"
          >
            <i class="fas fa-file-download" v-if="!saveChartInProgress"></i
            ><i class="fas fa-spinner fa-spin" v-if="saveChartInProgress"></i>
            Download / Print Calendar
          </button>&nbsp;&nbsp;<button
        type="button"
        class="btn btn-warning mb-2"
        @click="showEmailChart"
        v-if="!showEmailForm"
      >
        <i class="fas fa-envelope"></i> Email Calendar
      </button>
      <form
        class="row row-cols-lg-auto g-1 align-items-center mb-2"
        v-if="showEmailForm"
      >
        <div class="col-12">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              id="inlineEmailAddress"
              placeholder="Email Address"
              v-bind:disabled="sendEmailInProgress"
              v-model="email"
            />
          </div>
        </div>
        <div class="col-12">
          <button
            type="button"
            class="btn btn-warning"
            @click="emailChart"
            v-bind:disabled="sendEmailInProgress"
          >
            <i class="fas fa-paper-plane" v-if="!sendEmailInProgress"></i
            ><i class="fas fa-spinner fa-spin" v-if="sendEmailInProgress"></i>
            Send Email
          </button>
        </div>
      </form>
    </div>
    <div class="row" v-if="status == 'done'">
      <div class="col-12">
        <VuePdf ref='pdfViewer' v-if="pdfLink" :src="pdfLink" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { DateTime } from "luxon";
import { API } from "aws-amplify";
import { saveAs } from "file-saver";
import * as slugify from "slugify";
import { onBeforeRouteUpdate } from 'vue-router'

var BASE64_MARKER = ";base64,";

function convertDataURIToBinary(dataURI) {
  var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  var base64 = dataURI.substring(base64Index);
  var raw = window.atob(base64);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));

  for (var i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}

export default {
  name: "Calendar",
  components: {},
  data: () => ({
    pdfRaw: null,
    status: "new",
    saveChart: null,
    showEmailForm: false,
    sendEmailInProgress: false,
    email: null,
    saveChartInProgress: false,
  }),
  props: ["name", "gender", "birthday", "birthdayDisp", "showButtons", "month", "monthDisp"],
  computed: {
    pdfLink() {
      if (this.pdfRaw) {
        return convertDataURIToBinary(
          `data:application/pdf;base64,${this.pdfRaw}`
        );
      } else {
        return null;
      }
    },
  },
  mounted() {
    this.getChart();
  },
  setup() {
    onBeforeRouteUpdate((to) => {
      if (to.params['day']) {
        let message = JSON.parse(to.params['day'])
        alert(`${message[0]}\n${message[1]}\n${message[2]}`)
        return false;
      }
    })
  },
  methods: {
    newChart() {
      //todo
    },
    async printChart() {
      this.saveChartInProgress = true;      
      try {
        let chartPayload = {
          queryStringParameters: {
            birthday: this.birthday,
            gender: this.gender,
            name: this.name,
            month: this.month
          },
        };
        let pdf = await API.get("calendar", "/calendar", chartPayload);
        saveAs(
          `data:application/pdf;base64,${pdf}`,
          `${slugify(this.name, { remove: /"<>#%\{\}\|\\\^~\[\]`;\?:@=&/g })} (${this.monthDisp}).pdf`
        );
      } catch (e) {
        console.log(e);
        alert(
          "An error has occurred. Please refresh the page and try again later."
        );
      } finally {
        this.saveChartInProgress = false;
      }      
    },
    showEmailChart() {
      this.showEmailForm = true;
      this.email = null;
    },
    async emailChart() {
      this.sendEmailInProgress = true;      
      try {
        let chartPayload = {
          queryStringParameters: {
            birthday: this.birthday,
            gender: this.gender,
            name: this.name,
            month: this.month
          },
        };
        let pdf = await API.get("calendar", "/calendar", chartPayload);
        let emailPayload = {
          body: {
            email: this.email,
            pdf: `data:application/pdf;base64,${pdf}`,
            name: this.name,
            gender: this.gender == "m" ? "Male" : "Female",
            timestamp: DateTime.now().toLocaleString(DateTime.DATETIME_MED),
            birthday: this.birthdayDisp,
            type: 'calendar',
            monthDisp: this.monthDisp
          },
        };
        await API.post("admin", "/email", emailPayload);
        alert("Calendar sent!");
      } catch (e) {
        console.log(e);
        alert(
          "An error has occurred. Please refresh the page and try again later."
        );
      } finally {
        this.sendEmailInProgress = false;
        this.showEmailForm = false;
        this.email = null;
      }
    },
    async getChart() {
      this.status = "generating";
      const apiName = "calendar";
      const path = "/calendar";
      const myInit = {
        queryStringParameters: {
          birthday: this.birthday,
          gender: this.gender,
          name: this.name,
          month: this.month,
          webViewer: true
        },
      };

      try {
        const response = await API.get(apiName, path, myInit);
        this.pdfRaw = response;
        this.status = "done";
      } catch (e) {
        console.log(e);
        this.newChart();
        alert(
          "An error has occurred. Please refresh the page and try again later."
        );
      }
    },
    bdString(input) {
      return DateTime.fromISO(input).toLocaleString(DateTime.DATETIME_MED);
    },
  },
};
</script>

<style scoped>
@media (max-width: 991.98px) {
  .custom-mobile-text {
    font-size: 0.9rem;
  }
  .page-header {
    margin-top: -8px;
  }
}

.services-container {
  color: #939597;
}
.services-container h5.card-title {
  color: #75787b;
}
.services-container h5.card-title-blue {
  color: #003a70;
}
.services-container h5.date {
  color: #939597;
  font-size: 0.85rem;
}
.services-container .card-text {
  font-size: 0.8rem;
}
.services-container h5 {
  font-size: 1.1rem;
}
</style>
