<template>
  <div class="container">
    <div class="d-flex flex-column align-items-center mt-5 mb-4">
      <div class="yellow-line"></div>
      <div class="subheader fw-bold fs-3 text-uppercase text-center mb-2">
        Qimen Hourly Chart<br />奇门时盘
      </div>
      <div class="yellow-line"></div>
    </div>
    <form class="border px-3 py-3 mb-3" novalidate v-if="status == 'new'">
      <div class="row mb-3">
        <label class="col-sm-2 col-form-label pt-0"
          >Date</label
        >
        <div class="col-sm-3">
          <select
            class="form-select"
            v-model="day"
            :class="[
              !day && turnOnValidators ? 'is-invalid' : '',
              validDate ? '' : 'is-invalid',
            ]"
          >
            <option selected value="null">Select day 日</option>
            <option v-for="day in days" :value="day.label" :key="day.label">
              {{ day.label }}
            </option>
          </select>
        </div>
        <div class="col-sm-3">
          <select
            class="form-select"
            v-model="month"
            :class="[
              !month && turnOnValidators ? 'is-invalid' : '',
              validDate ? '' : 'is-invalid',
            ]"
          >
            <option selected value="null">Select month 月</option>
            <option
              v-for="month in months"
              :value="month.value"
              :key="month.value"
            >
              {{ month.label }}
            </option>
          </select>
        </div>
        <div class="col-sm-4">
          <select
            class="form-select"
            v-model="year"
            :class="[
              !year && turnOnValidators ? 'is-invalid' : '',
              validDate ? '' : 'is-invalid',
            ]"
          >
            <option selected value="null">Select year 年</option>
            <option v-for="year in years" :value="year.label" :key="year.label">
              {{ year.label }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mb-3">
        <label class="col-sm-2 col-form-label pt-0"
          >Time</label
        >
        <div class="col-sm-3">
          <select
            class="form-select"
            v-model="hour"
            v-bind:disabled="noTime"
            :class="[!hour && turnOnValidators && !noTime ? 'is-invalid' : '']"
          >
            <option selected value="null">Select hour 时</option>
            <option v-for="hour in hours" :value="hour.label" :key="hour.label">
              {{ hour.label }}
            </option>
          </select>
        </div>
        <div class="col-sm-3">
          <select
            class="form-select"
            v-model="minute"
            v-bind:disabled="noTime"
            :class="[
              !minute && turnOnValidators && !noTime ? 'is-invalid' : '',
            ]"
          >
            <option selected value="null">Select minute 分</option>
            <option
              v-for="minute in minutes"
              :value="minute.label"
              :key="minute.value"
            >
              {{ minute.label }}
            </option>
          </select>
        </div>
        <div class="col-sm-4">
          <select
            class="form-select"
            v-model="selectedTimeOfDay"
            v-bind:disabled="noTime"
            :class="[
              !selectedTimeOfDay && turnOnValidators && !noTime
                ? 'is-invalid'
                : '',
            ]"
          >
            <option selected value="null">Select AM/PM</option>
            <option v-for="t in timeOfDay" :value="t.label" :key="t.label">
              {{ t.label }}
            </option>
          </select>
        </div>
      </div>
    </form>
    <button
      type="button"
      class="btn btn-primary mb-3"
      @click="buyChart"
      v-if="status == 'new'"
    >
      Generate
    </button>&nbsp;
    <button
      type="button"
      class="btn btn-warning mb-3"
      @click="nowChart"
      v-if="status == 'new'"
    >
      <i class="fas fa-clock"></i> Now 现在
    </button>
    <div class="d-flex align-items-center mb-5" v-if="status == 'generating'">
      <div class="spinner-border text-danger" role="status"></div>
      <div class="ms-3">
        <strong>Generating chart...</strong>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-between mb-5" v-if="status == 'done'">
        <button type="button" class="btn btn-warning" @click="goPrevHour"><i class="fas fa-backward"></i> Previous Hour</button>
      <button type="button" class="btn btn-primary mb-2" @click="newChart">
        <i class="fas fa-plus"></i> New Chart</button
      ><button type="button" class="btn btn-warning" @click="goNextHour">Next Hour <i class="fas fa-forward"></i></button>
      <!-- <button
        type="button"
        class="btn btn-warning mb-2"
        @click="printChart"
        style="display: none;"
      >
        <i class="fas fa-file-download"></i> Download / Print Chart</button
      >&nbsp;&nbsp;<button
        type="button"
        class="btn btn-warning mb-2"
        @click="showEmailChart"
        v-if="!showEmailForm"
        style="display: none;"
      >
        <i class="fas fa-envelope"></i> Email Chart
      </button>
      <form
        class="row row-cols-lg-auto g-1 align-items-center mb-2"
        v-if="showEmailForm"
      >
        <div class="col-12">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              id="inlineEmailAddress"
              placeholder="Email Address"
              v-bind:disabled="sendEmailInProgress"
              v-model="email"
            />
          </div>
        </div>
        <div class="col-12">
          <button
            type="button"
            class="btn btn-warning"
            @click="emailChart"
            v-bind:disabled="sendEmailInProgress"
          >
            <i class="fas fa-paper-plane" v-if="!sendEmailInProgress"></i
            ><i class="fas fa-spinner fa-spin" v-if="sendEmailInProgress"></i>
            Send Email
          </button>
        </div>
      </form> -->
    </div>
    <div class="row" v-if="status == 'done'">
      <div class="col-12">
        <VuePdf v-if="pdfLink" :src="pdfLink" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { DateTime } from "luxon";
import { API } from "aws-amplify";
import { saveAs } from "file-saver";
import * as slugify from "slugify";

const inclusiveRange = (start, end, step) => {
  return Array.from(
    Array.from(Array(Math.ceil((end - start + 1) / step)).keys()),
    (x) => {
      return { label: start + x * step };
    }
  );
};
var BASE64_MARKER = ";base64,";

function convertDataURIToBinary(dataURI) {
  var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  var base64 = dataURI.substring(base64Index);
  var raw = window.atob(base64);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));

  for (var i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}

export default {
  name: "QimenHourly",
  components: {},
  data: () => ({
    days: Array.from(Array(31).keys(), (n) => {
      return { label: n + 1 };
    }),
    months: [
      { label: "Jan (1)", value: 1 },
      { label: "Feb (2)", value: 2 },
      { label: "Mar (3)", value: 3 },
      { label: "Apr (4)", value: 4 },
      { label: "May (5)", value: 5 },
      { label: "Jun (6)", value: 6 },
      { label: "Jul (7)", value: 7 },
      { label: "Aug (8)", value: 8 },
      { label: "Sep (9)", value: 9 },
      { label: "Oct (10)", value: 10 },
      { label: "Nov (11)", value: 11 },
      { label: "Dec (12)", value: 12 },
    ],
    years: inclusiveRange(2023, 1918, -1),
    hours: Array.from(Array(12).keys(), (n) => {
      return { label: n + 1 };
    }),
    minutes: Array.from(Array(60).keys(), (n) => {
      return { label: String(n).padStart(2, "0") };
    }),
    timeOfDay: Array.from(["AM", "PM"], (n) => {
      return { label: n };
    }),
    name: null,
    day: null,
    month: null,
    year: null,
    hour: null,
    minute: null,
    selectedTimeOfDay: null,
    noTime: false,
    gender: null,
    turnOnValidators: false,
    processingModal: false,
    pdfRaw: null,
    status: "new",
    saveChart: null,
    showEmailForm: false,
    sendEmailInProgress: false,
    email: null,
    addToWorkbench: 'n',
    timestamp: null,
  }),
  computed: {
    validDate() {
      if (this.year && this.month && this.day) {
        return DateTime.fromObject({
          year: this.year,
          month: this.month,
          day: this.day,
        }).isValid;
      }
      return true;
    },
    pdfLink() {
      if (this.pdfRaw) {
        return convertDataURIToBinary(
          `data:application/pdf;base64,${this.pdfRaw}`
        );
      } else {
        return null;
      }
    },
  },
  mounted() {},
  watch: {
    noTime(newState) {
      if (newState) {
        this.hour = null;
        this.minute = null;
        this.selectedTimeOfDay = null;
      }
    },
  },
  methods: {
    clearData() {
      this.hour = null;
      this.minute = null;
      this.selectedTimeOfDay = null;
      this.day = null;
      this.month = null;
      this.year = null;
      this.gender = null;
      this.noTime = false;
      this.name = null;
      this.turnOnValidators = false;
      this.processingModal = false;
      this.saveChart = null;
      this.email = null;
      this.addToWorkbench = 'n';
      this.timestamp = null;
    },
    newChart() {
      this.clearData();
      this.pdfRaw = null;
      this.status = "new";
      this.showEmailForm = false;
    },
    printChart() {
      saveAs(
        `data:application/pdf;base64,${this.pdfRaw}`,
        `${slugify(this.name, { remove: /"<>#%\{\}\|\\\^~\[\]`;\?:@=&/g })}.pdf`
      );
    },
    showEmailChart() {
      this.showEmailForm = true;
      this.email = null;
    },
    async emailChart() {
      //todo
      this.sendEmailInProgress = true;
      let hour = this.selectedTimeOfDay == "AM" ? this.hour : this.hour + 12;
      hour = hour == 12 ? "0" : hour;
      hour = hour == 24 ? "12" : hour;
      hour = String(hour).padStart(2, "0");

      const birthday = `${this.year}-${String(this.month).padStart(
        2,
        "0"
      )}-${String(this.day).padStart(2, "0")} ${this.noTime ? "" : hour + ":" + this.minute}`;
      const apiName = "admin";
      const path = "/email";
      const myInit = {
        body: {
          email: this.email,
          pdf: `data:application/pdf;base64,${this.pdfRaw}`,
          name: this.name,
          gender: this.gender == 'm' ? 'Male' : 'Female',
          timestamp: DateTime.now().toLocaleString(DateTime.DATETIME_MED),
          birthday,
        },
      };

      try {
        await API.post(apiName, path, myInit);
        alert("Chart sent!");
      } catch (e) {
        console.log(e);
        alert(
          "An error has occurred. Please refresh the page and try again later."
        );
      } finally {
        this.sendEmailInProgress = false;
        this.showEmailForm = false;
        this.email = null;
      }
    },
    async buyChart() {
      this.turnOnValidators = true;

      if (!this.validDate) {
        alert("Please select a valid date");
        return;
      }
      if (
        !this.year ||
        !this.month ||
        !this.day
      ) {
        return;
      }
      if (!this.noTime) {
        if (!this.hour || !this.minute || !this.selectedTimeOfDay) {
          return;
        }
      }      

      this.status = "generating";
      let hour = this.selectedTimeOfDay == "AM" ? this.hour : this.hour + 12;
      hour = hour == 12 ? "0" : hour;
      hour = hour == 24 ? "12" : hour;
      hour = String(hour).padStart(2, "0");

      const birthday = `${this.year}-${String(this.month).padStart(
        2,
        "0"
      )}-${String(this.day).padStart(2, "0")}T${this.noTime ? "99" : hour}:${
        this.noTime ? "99" : this.minute
      }`;
      this.timestamp = birthday;

      this.processingModal = true;
      const apiName = "admin";
      const path = "/qimen/hourly";
      const myInit = {
        queryStringParameters: {
          time: birthday,
        },
      };

      try {
        const response = await API.get(apiName, path, myInit);
        this.pdfRaw = response;
        this.status = "done";
      } catch (e) {
        console.log(e);
        this.newChart();
        alert(
          "An error has occurred. Please refresh the page and try again later."
        );
      }
    },
    async nowChart() {
      this.status = "generating";

      const birthday = DateTime.now().toISO().split("+")[0]
      this.timestamp = birthday;

      this.processingModal = true;
      const apiName = "admin";
      const path = "/qimen/hourly";
      const myInit = {
        queryStringParameters: {
          time: birthday,
        },
      };

      try {
        const response = await API.get(apiName, path, myInit);
        this.pdfRaw = response;
        this.status = "done";
      } catch (e) {
        console.log(e);
        this.newChart();
        alert(
          "An error has occurred. Please refresh the page and try again later."
        );
      }
    },
    async goPrevHour() {
      this.status = "generating";
      const birthday = DateTime.fromISO(this.timestamp).minus({ hours: 2 }).toISO().split(".")[0]
      this.timestamp = birthday;

      this.processingModal = true;
      const apiName = "admin";
      const path = "/qimen/hourly";
      const myInit = {
        queryStringParameters: {
          time: birthday,
        },
      };

      try {
        const response = await API.get(apiName, path, myInit);
        this.pdfRaw = response;
        this.status = "done";
      } catch (e) {
        console.log(e);
        this.newChart();
        alert(
          "An error has occurred. Please refresh the page and try again later."
        );
      }
    },
    async goNextHour() {
      this.status = "generating";
      const birthday = DateTime.fromISO(this.timestamp).plus({ hours: 2 }).toISO().split(".")[0]
      this.timestamp = birthday;

      this.processingModal = true;
      const apiName = "admin";
      const path = "/qimen/hourly";
      const myInit = {
        queryStringParameters: {
          time: birthday,
        },
      };

      try {
        const response = await API.get(apiName, path, myInit);
        this.pdfRaw = response;
        this.status = "done";
      } catch (e) {
        console.log(e);
        this.newChart();
        alert(
          "An error has occurred. Please refresh the page and try again later."
        );
      }
    },
    bdString(input) {
      return DateTime.fromISO(input).toLocaleString(DateTime.DATETIME_MED);
    },
  },
};
</script>

<style scoped>
@media (max-width: 991.98px) {
  .custom-mobile-text {
    font-size: 0.9rem;
  }
  .page-header {
    margin-top: -8px;
  }
}

.services-container {
  color: #939597;
}
.services-container h5.card-title {
  color: #75787b;
}
.services-container h5.card-title-blue {
  color: #003a70;
}
.services-container h5.date {
  color: #939597;
  font-size: 0.85rem;
}
.services-container .card-text {
  font-size: 0.8rem;
}
.services-container h5 {
  font-size: 1.1rem;
}
</style>