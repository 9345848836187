import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Viewer from '../views/Viewer.vue'
import Adhoc from '../views/Adhoc.vue'
import MultiChart from '../views/MultiChart.vue'
import Calendar from '../views/Calendar.vue'
import QimenHourly from '../views/QimenHourly.vue'
import NewUser from '../views/NewUser.vue'
import EventRegistration from '../views/EventRegistration.vue'
import CalendarRegistration from '../views/CalendarRegistration.vue'
import QimenTurnaround from '../views/QimenTurnaround.vue'

// const routes = [
//   {
//     path: '/',
//     name: 'Home',
//     component: Home
//   },
//   {
//     path: '/about',
//     name: 'About',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//   }
// ]

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/view',
    name: 'Viewer',
    component: Viewer,
    props: true,
  },
  {
    path: '/adhoc',
    name: 'Adhoc',
    component: Adhoc
  },
  {
    path: '/multichart',
    name: 'MultiChart',
    component: MultiChart
  },
  {
    path: '/calendar/:day?',
    name: 'Calendar',
    component: Calendar,
    props: true
  },
  {
    path: '/qimen/hourly',
    name: 'QimenHourly',
    component: QimenHourly
  },
  {
    path: '/newuser',
    name: 'NewUser',
    component: NewUser
  },
  {
    path: '/eventregistration',
    name: 'EventRegistration',
    component: EventRegistration
  },
  {
    path: '/calendarregistration',
    name: 'CalendarRegistration',
    component: CalendarRegistration
  },
  {
    path: '/qimenturnaround',
    name: 'QimenTurnaround',
    component: QimenTurnaround
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
