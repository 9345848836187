<template>
  <div class="container">
    <div class="d-flex align-items-center my-5" v-if="loading">
      <div class="spinner-border text-danger" role="status"></div>
      <div class="ms-3">
        <strong>Loading...</strong>
      </div>
    </div>
    <div class="overflow-auto">
    <div v-if="selectedCustomers.length > 0">
      <br/>
      <strong>Purchased: </strong>{{ selectedCustomers.length - sponsoredUsers }}<br/>
      <strong>Sponsored: </strong>{{ sponsoredUsers }}<br/>
    </div>
    <table
      class="table table-hover align-middle"
      v-if="selectedCustomers.length > 0"
      style="margin-bottom:405px;"
    >
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Gender</th>
          <th scope="col">Birthday</th>
          <th scope="col">Email address</th>
          <th scope="col">Type</th>
          <th scope="col">Purchased</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="customer in selectedCustomers" :key="customer.SK">
          <td>{{ customer.Name?.S }}</td>
          <td>{{ customer.Gender?.S }}</td>
          <td>{{ customer.Birthday ? modTime(customer.Birthday?.S) : '' }}</td>
          <td>{{ customer.PK?.S.split("#")[1] }}</td>
          <td>{{ customer.PaymentMethod?.S }}</td>
          <td>{{ customer.UpdatedAt ? modTime(customer.UpdatedAt?.S) : ''}}</td>
        </tr>
      </tbody>
    </table></div>
  </div>
</template>

<script>
// @ is an alias to /src
import { DateTime } from "luxon";
import { API } from "aws-amplify";
import * as _ from "lodash";

export default {
  name: "CalendarRegistration",
  components: {},
  data: () => ({
    loading: false,
    selectedCustomers: [],
  }),
  computed: {
    sponsoredUsers() {
      return _.filter(this.selectedCustomers, {
        PaymentMethod: {
          S: 'sponsored'
        }
      }).length
    }
  },
  mounted() {
    this.loading = true;
    
    const apiName = "admin";
    const path = "/getAllCalendar";
    API.get(apiName, path).then(r => {
      this.loading = false;
      this.selectedCustomers = _.filter(_.sortBy(r, s => s.UpdatedAt.S).reverse(), { PaymentStatus: { S: 'paid'}})
    })
  },
  watch: {},
  methods: {
    modTime(dt) {
      return dt.split("T")[1] == "99:99"
        ? DateTime.fromISO(
            dt.split("T")[0]
          ).toLocaleString(DateTime.DATE_MED) + " (No birth time)"
        : DateTime.fromISO(dt).toLocaleString(
            DateTime.DATETIME_MED
          );
    },
  },
};
</script>

<style scoped>
@media (max-width: 991.98px) {
  .custom-mobile-text {
    font-size: 0.9rem;
  }
  .page-header {
    margin-top: -8px;
  }
}

.services-container {
  color: #939597;
}
.services-container h5.card-title {
  color: #75787b;
}
.services-container h5.card-title-blue {
  color: #003a70;
}
.services-container h5.date {
  color: #939597;
  font-size: 0.85rem;
}
.services-container .card-text {
  font-size: 0.8rem;
}
.services-container h5 {
  font-size: 1.1rem;
}
</style>
