<template>
  <div class="container">
    <div class="d-flex align-items-center my-5" v-if="loading">
      <div class="spinner-border text-danger" role="status"></div>
      <div class="ms-3">
        <strong>Loading...</strong>
      </div>
    </div>
    <div v-if="selectedCustomers.length > 0">
      <br/>
      <strong>Singapore: </strong>{{ counts?.['paid@入门票 Admission Ticket']?.length }}<br/>
      <strong>Singapore (Sponsored): </strong>{{ counts?.['paid@Sponsored']?.length ? counts?.['paid@Sponsored']?.length : '0' }}<br/>
      <strong>Online: </strong>{{ counts?.['paid@线上入门票 Online Admission Ticket'] ? counts?.['paid@线上入门票 Online Admission Ticket']?.length : '0' }}
      <br/>
      <em style="font-size: 0.75rem;">Pending payment tickets are not included in the counts</em>
      <br/>&nbsp;
    </div>
    <div class="overflow-auto">
    <table
      class="table table-hover align-middle table-responsive"
      v-if="selectedCustomers.length > 0"
      style="margin-bottom:405px;"
    >
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Gender</th>
          <th scope="col">Birthday</th>
          <th scope="col">Email address</th>
          <th scope="col">Mobile number</th>
          <th scope="col">Payment method</th>
          <th scope="col">Payment status</th>
          <th scope="col">Confirmation code</th>
          <th scope="col">Ticket type</th>
          <th scope="col">Last updated</th>
          <th scope="col">Copy</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="customer in selectedCustomers" :key="customer.SK">
          <td>{{ customer.Name?.S }}</td>
          <td>{{ customer.Gender?.S }}</td>
          <td>{{ customer.Birthday ? modTime(customer.Birthday?.S) : '' }}</td>
          <td>{{ customer.EmailAddress?.S }}</td>
          <td>{{ customer.MobileNumber?.S }}</td>
          <td>{{ customer.PaymentMethod?.S }}</td>
          <td>{{ customer.PaymentStatus?.S }}</td>
          <td>{{ customer.PaymentStatus?.S == 'pending' ? '-' : customer.EventConfirmationCode?.S }}</td>
          <td>{{ customer.EventTier?.S }}</td>
          <td>{{ customer.UpdatedAt ? modTime(customer.UpdatedAt?.S) : ''}}</td>
          <td><button
              type="button"
              class="btn btn-outline-dark"
              @click="copyToDb(customer)"
            >
              <i class="fas fa-copy"></i> Copy to database</button
            ></td>
        </tr>
      </tbody>
    </table></div>
  </div>
</template>

<script>
// @ is an alias to /src
import { DateTime } from "luxon";
import { API } from "aws-amplify";
import * as _ from "lodash";

export default {
  name: "EventRegistration",
  components: {},
  data: () => ({
    loading: false,
    selectedCustomers: [],
    counts: {}
  }),
  computed: {
    
  },
  mounted() {
    this.loading = true;
    
    const apiName = "admin";
    const path = "/getAllRegistration";
    API.get(apiName, path).then(r => {
      this.loading = false;
      this.selectedCustomers = _.sortBy(r, s => s.UpdatedAt.S).reverse()
      this.counts = _.groupBy(r, s => `${s.PaymentStatus.S}@${s.EventTier.S}` )
    })
  },
  watch: {},
  methods: {
    modTime(dt) {
      return dt.split("T")[1] == "99:99"
        ? DateTime.fromISO(
            dt.split("T")[0]
          ).toLocaleString(DateTime.DATE_MED) + " (No birth time)"
        : DateTime.fromISO(dt).toLocaleString(
            DateTime.DATETIME_MED
          );
    },
    async copyToDb(customer) {
      const apiName = "admin";
      const path = "/copycustomertodb";
      const myInit = {
        queryStringParameters: {
          birthday: customer.Birthday.S,
          gender: customer.Gender.S,
          name: customer.Name.S,
        },
      };
      try {
        await API.get(apiName, path, myInit);
        alert("Copied successfully.")
      } catch (e) {
        console.log(e);
        alert(
          `An error has occurred. Please refresh the page and try again.\n${e}`
        );
      }
    }
  },
};
</script>

<style scoped>
@media (max-width: 991.98px) {
  .custom-mobile-text {
    font-size: 0.9rem;
  }
  .page-header {
    margin-top: -8px;
  }
}

.services-container {
  color: #939597;
}
.services-container h5.card-title {
  color: #75787b;
}
.services-container h5.card-title-blue {
  color: #003a70;
}
.services-container h5.date {
  color: #939597;
  font-size: 0.85rem;
}
.services-container .card-text {
  font-size: 0.8rem;
}
.services-container h5 {
  font-size: 1.1rem;
}
</style>
