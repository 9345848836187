<template>
  <div class="container">
      <splitpanes horizontal class="default-theme mt-5 mb-4" style="height: 1500px;">
        <pane v-for="customer in workbench" :key="customer.SK">
            <Viewer :name='customer.Name' :gender="customer.Gender == 'Male' ? 'm':'f'" :birthday="customer.Birthday" :birthdayDisp="customer.BirthdayDisp" :showButtons='false' />
        </pane>
      </splitpanes>
  </div>
</template>

<script>
// @ is an alias to /src
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import Viewer from '@/views/Viewer.vue'

export default {
  name: "MultiChart",
  components: { Splitpanes, Pane, Viewer },
  data: () => ({    
  }),
  computed: {
    workbench() {
      return this.$store.state.workbench;
    },
  },
  mounted() {
  },
  watch: {},
  methods: {
  },
};
</script>

<style scoped>
@media (max-width: 991.98px) {
  .custom-mobile-text {
    font-size: 0.9rem;
  }
  .page-header {
    margin-top: -8px;
  }
}

.services-container {
  color: #939597;
}
.services-container h5.card-title {
  color: #75787b;
}
.services-container h5.card-title-blue {
  color: #003a70;
}
.services-container h5.date {
  color: #939597;
  font-size: 0.85rem;
}
.services-container .card-text {
  font-size: 0.8rem;
}
.services-container h5 {
  font-size: 1.1rem;
}

.splitpanes__pane {
  overflow: scroll;
}
</style>
